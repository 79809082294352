import React from "react";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import "./App.css";
import bgBanner from "./bgbanner.gif";
import { About } from "./pages/About";
import { Archive } from "./pages/Archive";
import { Index } from "./pages/Index";

const App: React.FC = () => {
  return (
    <Router>
      <div className="pane">
        <div className="header">
          <Link to="/">
            <img height="65px" src={bgBanner} alt="DAISY OWL" />
          </Link>
        </div>

        <div className="topbanner">
          <a
            className="sunshine"
            // eslint-disable-next-line
            target="_blank"
            href="https://store.steampowered.com/app/2179850/Cobalt_Core/"
          >
            <b>Hey!</b> I made a video game about battling spaceships with funny
            animal people. It's got the same kind of sense of humor as Daisy
            Owl. You might like it!
            <br />- Ben
          </a>
        </div>

        <div className="topbanner">
          <Link to="/">Today's Strip</Link>
          <Link to="/archive">Archive</Link>
          <Link to="/about">About</Link>
        </div>

        <div className="main">
          <Route exact path="/" component={Index} />
          <Route exact path="/archive" component={Archive} />
          <Route exact path="/comic/:posted" component={Index} />
          <Route exact path="/about" component={About} />
        </div>
      </div>

      <p className="footer">
        Daisy Owl © 2008-{new Date().getFullYear()}{" "}
        <Link to="/about">Contact</Link>
      </p>
    </Router>
  );
};

export default App;
