import React from "react";

export function About() {
  document.title = "Daisy Owl - About";

  return (
    <div className="about">
      <p>Hi! I'm Ben Driscoll and I make this comic.</p>
      <p>It started in 2008 and continues to exist.</p>
      <p>
        Get in touch with me on twitter{" "}
        <a href="https://twitter.com/daisyowl">@daisyowl</a>
      </p>
      <p>
        Or email me at <a href="mailto:ben@daisyowl.com">ben@daisyowl.com</a>
      </p>
    </div>
  );
}
