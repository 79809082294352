import React from "react";
import { data } from "../data";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";

export function Index(props: RouteComponentProps<{ posted: string }>) {
  const specificComic = data.comics.find(
    c => c.posted === props.match.params.posted
  );

  document.title = specificComic
    ? `Daisy Owl - ${specificComic.title}`
    : "Daisy Owl";

  let comic = specificComic || data.comics[data.comics.length - 1];

  var next = data.comics
    .filter(c => c.id > comic.id)
    .sort((a, b) => a.id - b.id)[0];

  var prev = data.comics
    .filter(c => c.id < comic.id)
    .sort((a, b) => b.id - a.id)[0];

  var ext = comic.ext || "gif";

  const navBar = (
    <div className="navthing">
      <div className="nav">
        {prev ? (
          <Link to={`/comic/${prev.posted}`}>
            <b>&lt; Prev</b>
          </Link>
        ) : (
          <b className="nothing">&lt; Prev</b>
        )}

        <Link to={`/comic/${data.comics[0].posted}`}>First</Link>

        <Link to={"/"}>Current</Link>

        {next ? (
          <Link to={`/comic/${next.posted}`}>
            <b>Next ></b>
          </Link>
        ) : (
          <span className="nothing">
            <b>Next ></b>
          </span>
        )}
      </div>
    </div>
  );

  return (
    <>
      {navBar}
      <img
        className="comic"
        src={`/comic_images/${comic.id}.${ext}`}
        title={comic.alt}
        alt={`Daisy Owl - ${comic.title} - ${comic.posted}`}
      />
      {navBar}
    </>
  );
}
