import React from "react";
import { data } from "../data";
import { Link } from "react-router-dom";

export function Archive() {
  document.title = "Daisy Owl - Archive";

  return (
    <div className="archive">
      <h2>Comics:</h2>
      {data.comics.map(comic => (
        <div className="comic_link">
          <Link to={`/comic/${comic.posted}`}>{comic.title}</Link>{" "}
          <span>{comic.posted}</span>
        </div>
      ))}
    </div>
  );
}
