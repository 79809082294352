interface Data {
  comics: Comic[];
}

interface Comic {
  id: number;
  title: string;
  alt: string;
  posted: string;
  is_650: number;
  ext: string;
  link: string;
  is_live: number;
  rssstamp: string;
}

export const data: Data = {
  comics: [
    {
      id: 1,
      title: "Bungees",
      alt: "Daisy is a pragmatist.",
      posted: "2008-07-03",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-07-02 21:00:00"
    },
    {
      id: 2,
      title: "The Principal's Office",
      alt: "For an educator, she knows very little about biology.",
      posted: "2008-07-04",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-07-03 21:00:00"
    },
    {
      id: 3,
      title: "Getting Along",
      alt: "All elementary schools keep these kinds of records.",
      posted: "2008-07-05",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-07-04 21:00:00"
    },
    {
      id: 4,
      title: "The Fly",
      alt: "This is not an appropriate use of Microsoft software.",
      posted: "2008-07-06",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-07-05 21:00:00"
    },
    {
      id: 6,
      title: "Science",
      alt: "Some people think that science CAN be funny. It CAN.",
      posted: "2008-07-07",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 0,
      rssstamp: "2008-07-06 21:00:00"
    },
    {
      id: 7,
      title: "Getting Along II",
      alt:
        "He will eat that sandwich and then be sad because there is poop in it.",
      posted: "2008-07-08",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-07-07 21:00:00"
    },
    {
      id: 8,
      title: "Vocab",
      alt: "I have about 1000 more of these phrases.",
      posted: "2008-07-09",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-07-08 21:00:00"
    },
    {
      id: 9,
      title: "Aisle Six",
      alt: "This is based on a true story.",
      posted: "2008-07-10",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-07-09 21:00:00"
    },
    {
      id: 10,
      title: "The Conveyor Belt of Infinity",
      alt:
        "Page 134, Union Rulebook: No employee shall be sold in part or whole.",
      posted: "2008-07-11",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-07-10 21:00:00"
    },
    {
      id: 11,
      title: "The History Channel",
      alt: "A lot of people don't know that.",
      posted: "2008-07-12",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-07-11 21:00:00"
    },
    {
      id: 12,
      title: "Junk",
      alt: "This is the least popular product in the Owl-in-a-Cage line.",
      posted: "2008-07-13",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-07-12 21:00:00"
    },
    {
      id: 13,
      title: "On Their Own",
      alt:
        "The city's inspectors are basically ignorant when it comes to spit-and-twig contruction practices.",
      posted: "2008-07-14",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-07-13 21:00:00"
    },
    {
      id: 14,
      title: "Descent",
      alt: "",
      posted: "2008-07-15",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-07-14 21:00:00"
    },
    {
      id: 15,
      title: "Night Time",
      alt: "When you're scared, your mouth ripples. This is true.",
      posted: "2008-07-16",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-07-15 21:00:00"
    },
    {
      id: 16,
      title: "Locked Out",
      alt: "What Daisy lacks in patience, she makes up for in brick.",
      posted: "2008-07-17",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-07-16 21:00:00"
    },
    {
      id: 17,
      title: "Throw Like One Too",
      alt: "That was totally a warm up toss.",
      posted: "2008-07-18",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-07-17 21:00:00"
    },
    {
      id: 18,
      title: "Buddy!",
      alt: "Good advice.",
      posted: "2008-07-19",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-07-18 21:00:00"
    },
    {
      id: 19,
      title: "It Was Nothing",
      alt: "Daisy has a pretty accurate blameometer.",
      posted: "2008-07-20",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-07-19 21:00:00"
    },
    {
      id: 20,
      title: "Tough Crowd",
      alt: "The same dream, every night.",
      posted: "2008-07-21",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-07-20 21:00:00"
    },
    {
      id: 21,
      title: "Qualifications",
      alt: "Most of what people know about bears, they learned from comics.",
      posted: "2008-07-22",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-07-21 21:00:00"
    },
    {
      id: 22,
      title: "The Dream",
      alt: "The man is passionate about honey.",
      posted: "2008-07-27",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-07-26 21:00:00"
    },
    {
      id: 23,
      title: "The Airlock",
      alt:
        "When someone says close your mouth, you close your mouth and ask questions later.",
      posted: "2008-07-28",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-07-27 21:00:00"
    },
    {
      id: 24,
      title: "HNY-001",
      alt: "Honey-based computing is decades away, at the very least.",
      posted: "2008-07-29",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-07-28 21:00:00"
    },
    {
      id: 25,
      title: "Imagine",
      alt: "It is beyond me why such a machine would need anything but honey.",
      posted: "2008-07-31",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-07-30 21:00:00"
    },
    {
      id: 26,
      title: "Royalty",
      alt: "Some or all of this information may be false, I didn't check.",
      posted: "2008-08-01",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-07-31 21:00:00"
    },
    {
      id: 27,
      title: "The Legend of Steve",
      alt: "They do have bear daycare, it is called the forest.",
      posted: "2008-08-02",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-08-01 21:00:00"
    },
    {
      id: 28,
      title: "The Elders",
      alt: "These papers appear to be in order.",
      posted: "2008-08-05",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-08-04 21:00:00"
    },
    {
      id: 29,
      title: "The Drill",
      alt: "I know that game.",
      posted: "2008-08-06",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-08-05 21:00:00"
    },
    {
      id: 30,
      title: "Bear Roots",
      alt: "That man's name was Les Stroud.",
      posted: "2008-08-08",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-08-07 21:00:00"
    },
    {
      id: 31,
      title: "Speeding",
      alt: "It's a new program they're trying out.",
      posted: "2008-08-09",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-08-08 21:00:00"
    },
    {
      id: 32,
      title: "The Rest Stop",
      alt:
        "This rest stop is in New Jersey. I'm sorry, New Jersey, but it is. You will have to accept that.",
      posted: "2008-08-11",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-08-10 21:00:00"
    },
    {
      id: 33,
      title: "Free Samples",
      alt: "You can never be too careful in the honey business.",
      posted: "2008-08-12",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-08-11 21:00:00"
    },
    {
      id: 34,
      title: "Mount Ruin",
      alt:
        "Marmalade is basically the least threatening word in the English language.",
      posted: "2008-08-13",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-08-12 21:00:00"
    },
    {
      id: 35,
      title: "Mount Marmalade, pt 1",
      alt: "Steve is kind of a badass.",
      posted: "2008-08-14",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-08-13 21:00:00"
    },
    {
      id: 36,
      title: "Mount Marmalade, pt 2",
      alt:
        "I have no idea if that's true. I'm just making stuff up about bears now.",
      posted: "2008-08-17",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-08-16 21:00:00"
    },
    {
      id: 37,
      title: "Not Cool",
      alt: "I'll be accepting complaints in the comments section shortly.",
      posted: "2008-08-19",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-08-18 21:00:00"
    },
    {
      id: 38,
      title: "Roommates",
      alt: "There's got to be at least a little magic in that coffee.",
      posted: "2008-08-21",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-08-20 21:00:00"
    },
    {
      id: 39,
      title: "It is a Problem",
      alt: "Roland does not care for Bear Grylls.",
      posted: "0000-00-00",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 0,
      rssstamp: "0000-00-00 00:00:00"
    },
    {
      id: 40,
      title: "You Would Expect",
      alt: "",
      posted: "2008-08-22",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-08-21 21:00:00"
    },
    {
      id: 41,
      title: "Miscommunication",
      alt: "Steve is not using enough uvula.",
      posted: "2008-08-24",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-08-23 21:00:00"
    },
    {
      id: 42,
      title: "The Hat Collection",
      alt: "Dan has some strange ideas about storing produce.",
      posted: "2008-08-25",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-08-24 21:00:00"
    },
    {
      id: 43,
      title: "Passing Time",
      alt:
        "Even Mr. Owl has a breaking point. Don't worry. He cleaned up the pieces and apologized for his language.",
      posted: "2008-08-27",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-08-26 21:00:00"
    },
    {
      id: 44,
      title: "Translation",
      alt: "This kind of thing is fairly common.",
      posted: "2008-09-03",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-09-02 21:00:00"
    },
    {
      id: 45,
      title: "Steve's Parents",
      alt: "Steve and Roland are not great liars.",
      posted: "2008-09-05",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-09-04 21:00:00"
    },
    {
      id: 46,
      title: "Book Learning",
      alt: "[citation needed]",
      posted: "2008-09-08",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-09-07 21:00:00"
    },
    {
      id: 47,
      title: "Air Mattress",
      alt: "Dan is bad with names, and species.",
      posted: "2008-09-10",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-09-09 21:00:00"
    },
    {
      id: 48,
      title: "Steve's Bedroom",
      alt:
        "This is a fantastic tape to play in a gray Chevy Celebrity station wagon. If any still exist.",
      posted: "2008-09-12",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 0,
      rssstamp: "2008-09-11 21:00:00"
    },
    {
      id: 49,
      title: "Funnybone",
      alt:
        "The femur, now that is a bone's bone, you know what I mean? It is the standard I hold other bones to.",
      posted: "2008-09-15",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-09-14 21:00:00"
    },
    {
      id: 50,
      title: "A Different Time",
      alt: "They are set in their ways.",
      posted: "2008-09-16",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-09-15 21:00:00"
    },
    {
      id: 51,
      title: "Expiration Dates",
      alt: "This pains Daisy.",
      posted: "2008-09-17",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-09-16 21:00:00"
    },
    {
      id: 52,
      title: "Owl Parenting",
      alt: "This can be a difficult topic for many owls.",
      posted: "2008-09-18",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-09-17 21:00:00"
    },
    {
      id: 53,
      title: "The Bully",
      alt: "Daisy had to make sure Cooper knew she was not a lawyer.",
      posted: "2008-09-22",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-09-21 21:00:00"
    },
    {
      id: 54,
      title: "Daisy is So Smart",
      alt: "You should warn someone before you bring out a number like that.",
      posted: "2008-09-23",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-09-22 21:00:00"
    },
    {
      id: 55,
      title: "A Rare Condition",
      alt: "Jeremy tried to read a Carl Sagan book once. It nearly killed him.",
      posted: "2008-09-24",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-09-23 21:00:00"
    },
    {
      id: 56,
      title: "The Whole Thing",
      alt: "The shape of the cake was important enough to warrant a mention.",
      posted: "2008-09-25",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-09-24 21:00:00"
    },
    {
      id: 57,
      title: "Honey Laser",
      alt: "Chester is so upset, his tie is dilberting.",
      posted: "2008-09-26",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-09-25 21:00:00"
    },
    {
      id: 58,
      title: "Kids These Days",
      alt: "Pool is an awkward game for owls to play.",
      posted: "2008-09-29",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-09-28 21:00:00"
    },
    {
      id: 59,
      title: "Truth",
      alt: "Nice comeback, Steve.",
      posted: "2008-09-30",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-09-29 21:00:00"
    },
    {
      id: 60,
      title: "Bear Tavern",
      alt:
        "It's mostly bears, but some pelicans have been showing up recently.",
      posted: "2008-10-02",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-10-01 21:00:00"
    },
    {
      id: 61,
      title: "Mondays",
      alt: "The shampoo is not intended for bears.",
      posted: "2008-10-06",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-10-05 21:00:00"
    },
    {
      id: 62,
      title: "Late",
      alt:
        "Steve should try jumping his car with that monster cell phone. What is this, 1997? Jesus.",
      posted: "2008-10-08",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-10-07 21:00:00"
    },
    {
      id: 63,
      title: "Repairs",
      alt: "Apparently this is what Steve needed help doing.",
      posted: "2008-10-09",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-10-08 21:00:00"
    },
    {
      id: 64,
      title: "The Gallon",
      alt: "It just got too real for Cooper.",
      posted: "2008-10-14",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-10-13 21:00:00"
    },
    {
      id: 65,
      title: "Fall",
      alt: "",
      posted: "2008-10-16",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-10-15 21:00:00"
    },
    {
      id: 66,
      title: "Car Talk",
      alt:
        "Under the hood, Steve's car is a jumble of vaguely mechanical looking parts. No wonder it isn't starting.",
      posted: "2008-10-17",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-10-16 21:00:00"
    },
    {
      id: 67,
      title: "Caves",
      alt: "Steve is not allowed to use the oven until spring.",
      posted: "2008-10-20",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-10-19 21:00:00"
    },
    {
      id: 68,
      title: "Sleep Lab",
      alt: "Turns out, the centrifuge is part of the experiment.",
      posted: "2008-10-21",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-10-20 21:00:00"
    },
    {
      id: 69,
      title: "Countdown",
      alt: "For this kind of measurement, the unit is always large mammals.",
      posted: "2008-10-23",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-10-22 21:00:00"
    },
    {
      id: 70,
      title: "Time Travel",
      alt: "What happened in the centrifuge? Tune in Monday to find out!",
      posted: "2008-10-24",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-10-23 21:00:00"
    },
    {
      id: 71,
      title: "Side Effects",
      alt: "The bicycle and trash can were not insured.",
      posted: "2008-10-27",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-10-26 21:00:00"
    },
    {
      id: 72,
      title: "Costumes",
      alt: "Cue the intro to Thriller at panel four, please.",
      posted: "2008-10-30",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-10-29 21:00:00"
    },
    {
      id: 73,
      title: "Halloween Breakfast",
      alt: "Beartato Puffs are well liked.",
      posted: "2008-11-01",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-10-31 21:00:00"
    },
    {
      id: 74,
      title: "Porridge",
      alt: "AWKWARD.",
      posted: "2008-11-04",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-11-03 21:00:00"
    },
    {
      id: 75,
      title: "Emergency Monkey",
      alt: "0-10 beats per minute is actually normal for a stuffed animal.",
      posted: "2008-11-06",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-11-05 21:00:00"
    },
    {
      id: 76,
      title: "Medical Science",
      alt: "The system is flawed.",
      posted: "2008-11-08",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-11-07 21:00:00"
    },
    {
      id: 77,
      title: "Recovery Monkey",
      alt: "Unusual, but not unheard of.",
      posted: "2008-11-11",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-11-10 21:00:00"
    },
    {
      id: 78,
      title: "Yearbook",
      alt: "Guess who was voted most likely to be an owl.",
      posted: "2008-11-14",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-11-13 21:00:00"
    },
    {
      id: 79,
      title: "Carpool",
      alt: "Try explaining that to the insurance people.",
      posted: "2008-11-18",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-11-17 21:00:00"
    },
    {
      id: 80,
      title: "GBI",
      alt:
        "Unlike other financial sectors, goofy bear business is always booming.",
      posted: "2008-11-22",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-11-21 21:00:00"
    },
    {
      id: 81,
      title: "Crumb Science",
      alt: "It's not just Cooper, all small children have this gift.",
      posted: "2008-11-25",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-11-24 21:00:00"
    },
    {
      id: 82,
      title: "Bear Hypnosis",
      alt: "This needs to be addressed in future sessions.",
      posted: "2008-11-26",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-11-25 21:00:00"
    },
    {
      id: 83,
      title: "Accidents",
      alt: "That is just a ridiculous thing to suggest.",
      posted: "2008-12-01",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-11-30 21:00:00"
    },
    {
      id: 84,
      title: "Dinosaur vs. Firetruck",
      alt: "The kids watch a lot of science shows.",
      posted: "2008-12-04",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-12-03 21:00:00"
    },
    {
      id: 85,
      title: "Cooper's Bad Dream",
      alt: "In the next strip, Steve gets hit with a pie.",
      posted: "2008-12-10",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-12-09 21:00:00"
    },
    {
      id: 86,
      title: "Interpretation",
      alt: "Never let the pie get the knife, that's a rookie mistake.",
      posted: "2008-12-11",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-12-10 21:00:00"
    },
    {
      id: 87,
      title: "Pie Test",
      alt:
        "Most problems in life can be solved with a delicious pie. [citation needed]",
      posted: "2008-12-12",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-12-11 21:00:00"
    },
    {
      id: 88,
      title: "Shopping",
      alt: "She's a keeper.",
      posted: "2008-12-17",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-12-16 21:00:00"
    },
    {
      id: 89,
      title: "Smooth",
      alt: "Steve is bad.",
      posted: "2008-12-19",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-12-18 21:00:00"
    },
    {
      id: 90,
      title: "Waffles and Candles",
      alt: "You always have to be one step ahead of your drunk friends.",
      posted: "2008-12-24",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-12-23 21:00:00"
    },
    {
      id: 91,
      title: "Future Stuff",
      alt: "Leave the real science to Gallagher, kids.",
      posted: "2008-12-29",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-12-28 21:00:00"
    },
    {
      id: 92,
      title: "Steve's Phone",
      alt:
        "Steve's cellular telephone is so old, you can't abbreviate it to cell phone.",
      posted: "2008-12-31",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2008-12-30 21:00:00"
    },
    {
      id: 93,
      title: "Winter",
      alt: "This is actually considered a successful run.",
      posted: "2009-01-04",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-01-03 21:00:00"
    },
    {
      id: 94,
      title: "Bears on Phones",
      alt: "For this strip, it's important to understand that Dan is a wizard.",
      posted: "2009-01-07",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-01-06 21:00:00"
    },
    {
      id: 95,
      title: "Business Casual",
      alt:
        "So apparently like 12 of those 17 minutes were spent finding a top hat. If you do the math.",
      posted: "2009-01-14",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-01-13 21:00:00"
    },
    {
      id: 96,
      title: "Movie Night",
      alt:
        "Watch that movie again. Add it to your list of things to do in the immediate future.",
      posted: "2009-01-16",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-01-15 21:00:00"
    },
    {
      id: 97,
      title: "Kate's Party",
      alt: "A group of bears is known as a 'sloth of bears.' Now you know.",
      posted: "2009-01-22",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-01-21 21:00:00"
    },
    {
      id: 98,
      title: "Hey Now",
      alt: "That's a lie, Smashmouth is as zany as it gets.",
      posted: "2009-01-26",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-01-25 21:00:00"
    },
    {
      id: 99,
      title: "Monocle",
      alt: "A monocle can really do that, be careful.",
      posted: "2009-01-29",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-01-28 21:00:00"
    },
    {
      id: 100,
      title: "Grolar Bears",
      alt: "I don't know who's in charge of deciding what's cool these days.",
      posted: "2009-01-31",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-01-30 21:00:00"
    },
    {
      id: 101,
      title: "The Offer",
      alt:
        "Okay but what if instead of cash, we gave out gold necklaces and puffy jackets? No?",
      posted: "2009-02-04",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 0,
      rssstamp: "2009-02-03 21:00:00"
    },
    {
      id: 102,
      title: "Wholesome",
      alt: "Kate likes her apartment to smell like a band doesn't live there.",
      posted: "2009-02-05",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 0,
      rssstamp: "2009-02-04 21:00:00"
    },
    {
      id: 103,
      title: "The Valley",
      alt: "",
      posted: "2009-02-06",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-02-05 21:00:00"
    },
    {
      id: 104,
      title: "Industrial Revolution",
      alt:
        "There are a lot of things that don't have dinosaurs in them, and Cooper is going to have to come to terms with that.",
      posted: "2009-02-11",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-02-10 21:00:00"
    },
    {
      id: 105,
      title: "Ballers",
      alt:
        "You play basketball the same way a vacuum cleaner plays basketball. In the sense that a vacuum cleaner cannot play basketball.",
      posted: "2009-02-16",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-02-15 21:00:00"
    },
    {
      id: 106,
      title: "Can Phone",
      alt:
        "You know the old saying with wedgies. Quick, painless, or clean. Choose two.",
      posted: "2009-02-19",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-02-18 21:00:00"
    },
    {
      id: 107,
      title: "Conflict Resolution",
      alt: "It's true. You can only solve forest problems that way.",
      posted: "2009-02-21",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-02-20 21:00:00"
    },
    {
      id: 108,
      title: "Park Ranger",
      alt: "I mean really, would a bear WEAR A HAT? Let's get serious here.",
      posted: "2009-02-26",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-02-25 21:00:00"
    },
    {
      id: 109,
      title: "Hot Tub",
      alt: "Now if Steve took his hat off, THAT would be weird.",
      posted: "2009-03-02",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-03-01 21:00:00"
    },
    {
      id: 110,
      title: "Mr. Owl",
      alt: "What you are seeing in panel two is the miracle of life.",
      posted: "2009-03-09",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-03-08 21:00:00"
    },
    {
      id: 111,
      title: "Steve",
      alt:
        "Steve is currently in the 'toaster fire' stage of life that we all go through at some point.",
      posted: "2009-03-10",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-03-09 21:00:00"
    },
    {
      id: 112,
      title: "Computers",
      alt: "That's double density, son.",
      posted: "2009-03-13",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-03-12 21:00:00"
    },
    {
      id: 113,
      title: "Chatlog",
      alt: "That's not exactly what Steve does at work, but it's close enough.",
      posted: "2009-03-18",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-03-17 21:00:00"
    },
    {
      id: 114,
      title: "Thrifty",
      alt:
        "Cooper knows how hard it would be to take care of a real Stegosaurus. In this way, he's mature beyond his years.",
      posted: "2009-03-23",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-03-22 21:00:00"
    },
    {
      id: 115,
      title: "PSA Theater",
      alt:
        "What you're seeing in panel six is a little something called the Magic of Theater.",
      posted: "2009-03-25",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-03-24 21:00:00"
    },
    {
      id: 116,
      title: "The Futon of Shame",
      alt:
        "His technique is all wrong. The best way to lift a futon mattress is with your knees, into a woodchipper.",
      posted: "2009-03-27",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-03-26 21:00:00"
    },
    {
      id: 117,
      title: "Lifting Technique",
      alt:
        "Modern science says that futons cannot hate, or have any emotions at all.",
      posted: "2009-03-31",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-03-30 21:00:00"
    },
    {
      id: 118,
      title: "Embarassing Dad",
      alt:
        "There was also that time he got stuck under a futon, remember that?",
      posted: "2009-04-02",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-04-01 21:00:00"
    },
    {
      id: 119,
      title: "Frustrometer",
      alt: "Frustrons are terrible particles that can even penetrate mittens.",
      posted: "2009-04-06",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-04-05 21:00:00"
    },
    {
      id: 120,
      title: "Proximity",
      alt: "It happens to everyone who knows Steve for any length of time.",
      posted: "2009-04-09",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-04-08 21:00:00"
    },
    {
      id: 121,
      title: "Laundry Pile",
      alt:
        "The company that makes Mr. Owl's washing machine is partly owned by Taco Bell and DARPA.",
      posted: "2009-04-11",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-04-10 21:00:00"
    },
    {
      id: 122,
      title: "Grampa",
      alt:
        "Every male, of every species, has a gene for 'child throwing' that gets activated when they become grandparents.",
      posted: "2009-04-13",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-04-12 21:00:00"
    },
    {
      id: 123,
      title: "Cake",
      alt: "Give the gift that keeps on giving. Dinosaur atoms.",
      posted: "2009-04-17",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-04-16 21:00:00"
    },
    {
      id: 124,
      title: "Bees!",
      alt: "Bees rock! They will sting the crap of you! Make a note of it!",
      posted: "2009-04-21",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-04-20 21:00:00"
    },
    {
      id: 125,
      title: "Queen Bee",
      alt:
        "It's like the saying goes, everyone gets their 15 minutes of being the matriarch of an insect colony.",
      posted: "2009-04-24",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-04-23 21:00:00"
    },
    {
      id: 126,
      title: "Sea of Bees",
      alt: "This is what bees do all day.",
      posted: "2009-04-28",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-04-27 21:00:00"
    },
    {
      id: 127,
      title: "Analogy",
      alt:
        "Bears, after pretending to be queen bees, often make terrible analogies. Check Wikipedia if you don't believe me.",
      posted: "2009-04-30",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-04-29 21:00:00"
    },
    {
      id: 128,
      title: "Depressing Statistics",
      alt:
        "For extra credit, divide the cost of a Mars mission by various politically charged numbers. And show your work! This will be on the test!",
      posted: "2009-05-05",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 0,
      rssstamp: "2009-05-04 21:00:00"
    },
    {
      id: 129,
      title: "Cheese",
      alt: "Everyone is at least a little bit afraid of cheese.",
      posted: "2009-05-08",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-05-07 21:00:00"
    },
    {
      id: 130,
      title: "Owl Attack",
      alt:
        "Mr. Owl's pellets are generally not very interesting. That's an ornithology joke, everyone. Round of applause. Alright.",
      posted: "2009-05-13",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-05-12 21:00:00"
    },
    {
      id: 131,
      title: "Underwear Pants",
      alt:
        "'Wow' is said three times in this comic. I wanted to say it four times, but that would be too many. Too many.",
      posted: "2009-05-16",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-05-15 21:00:00"
    },
    {
      id: 132,
      title: "Beans",
      alt: "Beans.",
      posted: "2009-05-19",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-05-18 21:00:00"
    },
    {
      id: 133,
      title: "Hair Beam",
      alt:
        "Don't try this at home. And if you do, definitely don't use it on an unwitting friend and then put it on youtube. That would be terrible. Just terrible.",
      posted: "2009-05-22",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-05-21 21:00:00"
    },
    {
      id: 134,
      title: "Steve, Cereal",
      alt:
        "An oil painting of a polar bear eating a bowl of cereal. This is what I have chosen to do with my life. I have no regrets.",
      posted: "2009-05-26",
      is_650: 1,
      ext: "jpg",
      link: "http://daisyowl.com/store/posters/",
      is_live: 1,
      rssstamp: "2009-05-25 21:00:00"
    },
    {
      id: 150,
      title: "Transponder",
      alt: "Cabinet doors on accordion springy things: the hidden terror.",
      posted: "2009-07-31",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-07-30 21:00:00"
    },
    {
      id: 135,
      title: "Telephone Baby",
      alt: "Telephone Baby's calls are as adorable as they are untraceable.",
      posted: "2009-05-29",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-05-28 21:00:00"
    },
    {
      id: 136,
      title: "Kate, Superbear",
      alt:
        "The weather forecast called for fluffy, idyllic clouds that vanish mysteriously after panel one.",
      posted: "2009-06-04",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-06-03 21:00:00"
    },
    {
      id: 137,
      title: "Cheese Two",
      alt: "That light bulb is literally swinging from all the drama.",
      posted: "2009-06-09",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-06-08 21:00:00"
    },
    {
      id: 138,
      title: "Boogers",
      alt: "We all choose a path in life, this is Steve's.",
      posted: "2009-06-12",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-06-11 21:00:00"
    },
    {
      id: 139,
      title: "The Dentist",
      alt: "Mr. Owl has a fantastic poker face.",
      posted: "2009-06-17",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-06-16 21:00:00"
    },
    {
      id: 140,
      title: "Stickers",
      alt:
        "They also had MY OTHER CAR IS A TOOTH, but you need a special license to own something THAT META, and Cooper is not 18 yet. ",
      posted: "2009-06-19",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-06-18 21:00:00"
    },
    {
      id: 141,
      title: "Landing",
      alt: "Rocket ships crashing in your backyard, people, am I right",
      posted: "2009-06-25",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-06-24 21:00:00"
    },
    {
      id: 142,
      title: "Hoot",
      alt: "What do they not understand about hoo?",
      posted: "2009-06-29",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-06-28 21:00:00"
    },
    {
      id: 143,
      title: "Downrange",
      alt:
        "You save a lot of money on rent if you are okay with having rockets fired at you.",
      posted: "2009-07-03",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-07-02 21:00:00"
    },
    {
      id: 144,
      title: "Guests",
      alt: "Time to set that futon up, buddy.",
      posted: "2009-07-07",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-07-06 21:00:00"
    },
    {
      id: 145,
      title: "Telegram",
      alt:
        "Steve is using punctuation that looks like a facial expression. The ladies love it.",
      posted: "2009-07-11",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-07-10 21:00:00"
    },
    {
      id: 146,
      title: "Flying",
      alt:
        "One beer and Steve starts wishing he was a different species. Lightweight.",
      posted: "2009-07-15",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-07-14 21:00:00"
    },
    {
      id: 147,
      title: "DMV",
      alt: "The DMV people amirite",
      posted: "2009-07-20",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-07-19 21:00:00"
    },
    {
      id: 148,
      title: "DMV II",
      alt: "Rudeness at the DMV is a tradition held in the highest honor.",
      posted: "2009-07-23",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-07-22 21:00:00"
    },
    {
      id: 149,
      title: "Owl Cuisine",
      alt:
        "The sound effect in panel 7 was originally 'BIB BIB BIB,' but I felt that the world was not ready.",
      posted: "2009-07-28",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-07-27 21:00:00"
    },
    {
      id: 151,
      title: "Octopuses",
      alt: "Octopuses don't take sass.",
      posted: "2009-08-04",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-08-03 21:00:00"
    },
    {
      id: 152,
      title: "Drink Experiment",
      alt: "It is a rule that both man and beast must obey.",
      posted: "2009-08-10",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-08-09 21:00:00"
    },
    {
      id: 153,
      title: "Owlatron",
      alt:
        "When I wrote this comic, I made a personal note to draw Steve 'flexing outrageously' in panel three.",
      posted: "2009-08-17",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-08-16 21:00:00"
    },
    {
      id: 154,
      title: "Steve, Hip",
      alt: "The puffiness of the jacket serves a rap-related purpose.",
      posted: "2009-08-20",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-08-19 21:00:00"
    },
    {
      id: 155,
      title: "Futon Rage",
      alt: "Futons are uncooperative in the best of times.",
      posted: "2009-08-25",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-08-24 21:00:00"
    },
    {
      id: 156,
      title: "Futon Attack",
      alt: "Mr. Owl owns a pitchfork for just such an eventuality.",
      posted: "2009-08-28",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-08-27 21:00:00"
    },
    {
      id: 157,
      title: "Owl Booboo",
      alt: "This is a sensitive subject for most animals.",
      posted: "2009-09-03",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-09-02 21:00:00"
    },
    {
      id: 158,
      title: "Prescription Strength",
      alt: "The balloon is a metaphor, which represents a balloon.",
      posted: "2009-09-09",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-09-08 21:00:00"
    },
    {
      id: 159,
      title: "Balloons",
      alt:
        "Hey, one of those balloons is an owl! Also, this is a poster! Click the comic if you want one!",
      posted: "2009-09-11",
      is_650: 1,
      ext: "",
      link: "http://www.daisyowl.com/store/posters/",
      is_live: 1,
      rssstamp: "2009-09-10 21:00:00"
    },
    {
      id: 160,
      title: "Pamphlet",
      alt: "Helium is kind of insecure.",
      posted: "2009-09-15",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-09-14 21:00:00"
    },
    {
      id: 161,
      title: "Dignity",
      alt: "Owls are like this.",
      posted: "2009-09-21",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-09-20 21:00:00"
    },
    {
      id: 162,
      title: "Intervention",
      alt: "Don't click this",
      posted: "2009-09-23",
      is_650: 1,
      ext: "",
      link: "http://daisyowl.com/comic_images/162_2.gif",
      is_live: 1,
      rssstamp: "2009-09-22 21:00:00"
    },
    {
      id: 163,
      title: "Day of the Bean",
      alt: "They say the animals can feel Bean Day coming before humans.",
      posted: "2009-09-30",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-09-29 21:00:00"
    },
    {
      id: 164,
      title: "Day of the Bean II",
      alt: "Bean Day is important enough for Mr. Owl to walk to.",
      posted: "2009-10-02",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-10-01 21:00:00"
    },
    {
      id: 165,
      title: "Day of the Bean III",
      alt: "This is a comic about a bear that farted.",
      posted: "2009-10-07",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-10-06 21:00:00"
    },
    {
      id: 166,
      title: "Cooper, Chair",
      alt: "This is a real 16x12 inch painting! Click it if you want it!",
      posted: "2009-10-12",
      is_650: 1,
      ext: "jpg",
      link: "http://cgi.ebay.com/ws/eBayISAPI.dll?ViewItem&item=170393731028",
      is_live: 1,
      rssstamp: "2009-10-11 21:00:00"
    },
    {
      id: 167,
      title: "Delivery",
      alt: "30 minutes or your space babies are free!",
      posted: "2009-10-15",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-10-14 21:00:00"
    },
    {
      id: 168,
      title: "Storage",
      alt: "That's basically how space babies work.",
      posted: "2009-10-19",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-10-18 21:00:00"
    },
    {
      id: 169,
      title: "Rocket Manual",
      alt:
        "Of COURSE this is a poster, how long have you known me? Click the image if you want one!",
      posted: "2009-10-22",
      is_650: 1,
      ext: "",
      link: "http://daisyowl.com/store/posters",
      is_live: 1,
      rssstamp: "2009-10-21 21:00:00"
    },
    {
      id: 170,
      title: "Launch",
      alt: "You'd be surprised how often this happens.",
      posted: "2009-10-29",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-10-28 21:00:00"
    },
    {
      id: 171,
      title: "Forecast",
      alt:
        "Mr. Owl doesn't have a subscription to any newspapers, but they mysteriously show up when they serve a comedic purpose. Pretty weird.",
      posted: "2009-11-04",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-11-03 21:00:00"
    },
    {
      id: 172,
      title: "Orbit",
      alt: "Space babies sound a lot better on paper.",
      posted: "2009-11-06",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-11-05 21:00:00"
    },
    {
      id: 173,
      title: "Escape Capsule",
      alt: "Rejected onomatopoeia: Panel 5 - AFFIX!",
      posted: "2009-11-11",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-11-10 21:00:00"
    },
    {
      id: 174,
      title: "Baby Store",
      alt: "November is National-HURK-Comics-Month (AKA Hurkvember!) Join in!",
      posted: "2009-11-13",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-11-12 21:00:00"
    },
    {
      id: 175,
      title: "Crash",
      alt:
        "The crash was so intense that it had colors. Also, this is a poster! Click it if you want one!",
      posted: "2009-11-18",
      is_650: 1,
      ext: "png",
      link: "/store/posters/",
      is_live: 1,
      rssstamp: "2009-11-17 21:00:00"
    },
    {
      id: 176,
      title: "Guy Stuff",
      alt: "That's right, ladies.",
      posted: "2009-11-23",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-11-22 21:00:00"
    },
    {
      id: 177,
      title: "Sci Fi",
      alt:
        "Many thanks to Technohawk, who is writing a book that is only SLIGHTLY LESS RIDICULOUS than I am making it out to be.",
      posted: "2009-11-25",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-11-24 21:00:00"
    },
    {
      id: 178,
      title: "Backpack",
      alt: "No one can do it",
      posted: "2009-12-02",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-12-01 21:00:00"
    },
    {
      id: 179,
      title: "Rolling In",
      alt: "The monkey is giving Steve the thumbs up, this is important.",
      posted: "2009-12-04",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-12-03 21:00:00"
    },
    {
      id: 180,
      title: "Sousaphone",
      alt: "It is completely different.",
      posted: "2009-12-11",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-12-10 21:00:00"
    },
    {
      id: 181,
      title: "Seussmobile",
      alt: "This is the kind of danger that Jack Conte confronts, daily.",
      posted: "2009-12-16",
      is_650: 1,
      ext: "",
      link: "http://www.youtube.com/watch?v=dJnYqrC_jfs&fmt=18",
      is_live: 1,
      rssstamp: "2009-12-15 21:00:00"
    },
    {
      id: 182,
      title: "Bugleface",
      alt:
        "Bugleface is a rare condition, but easily diagnosed. There are home tests you can buy. And of course, wash your hands after touching any bugles.",
      posted: "2009-12-21",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2009-12-20 21:00:00"
    },
    {
      id: 183,
      title: "Treatment",
      alt: "He is a pretty lazy doctor.",
      posted: "2010-01-04",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2010-01-03 21:00:00"
    },
    {
      id: 184,
      title: "Prescription",
      alt:
        "Existential crisis is the best medicine. Or was that laughter? I guess penicillin is pretty good too.",
      posted: "2010-01-07",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2010-01-06 21:00:00"
    },
    {
      id: 185,
      title: "Field Trip",
      alt:
        "Mrs. Principal has a nurse with her at all times, kind of like Idi Amin, but also different in many ways.",
      posted: "2010-01-12",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2010-01-11 21:00:00"
    },
    {
      id: 186,
      title: "Wanted",
      alt: "The mustachiometer is tube driven, for extra warmth.",
      posted: "2010-01-18",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2010-01-17 21:00:00"
    },
    {
      id: 187,
      title: "Pew pew pew",
      alt:
        "I have never been trapped in a net, I don't know if it is frightening or not.",
      posted: "2010-01-26",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2010-01-25 21:00:00"
    },
    {
      id: 188,
      title: "Crumple",
      alt: "Sometimes paper makes that sound when it crumples.",
      posted: "2010-01-31",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2010-01-30 21:00:00"
    },
    {
      id: 189,
      title: "Customer Service",
      alt:
        "Guns that shoot bear traps, truly the most dangerous weapon of all.",
      posted: "2010-02-04",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2010-02-03 21:00:00"
    },
    {
      id: 190,
      title: "Space Hobos",
      alt: "That is what hobos say.",
      posted: "2010-02-10",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2010-02-09 21:00:00"
    },
    {
      id: 191,
      title: "Space School",
      alt: "Consternation!",
      posted: "2010-02-17",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2010-02-16 21:00:00"
    },
    {
      id: 192,
      title: "Meanwhile IN SPACE",
      alt:
        "Everything in panel one is to scale, anyone who disagrees is a filthy liar.",
      posted: "2010-02-21",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2010-02-20 21:00:00"
    },
    {
      id: 193,
      title: "Space Phone",
      alt: "It's always in the last universe you think to look.",
      posted: "2010-03-02",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2010-03-01 21:00:00"
    },
    {
      id: 194,
      title: "Space Diner",
      alt: "I made a timelapse out of this comic, click to check it out!",
      posted: "2010-03-06",
      is_650: 1,
      ext: "",
      link: "http://www.youtube.com/watch?v=JZaEinBXw6k",
      is_live: 1,
      rssstamp: "2010-03-05 21:00:00"
    },
    {
      id: 195,
      title: "Fighting Bears",
      alt:
        "Their space suits have a special airlock for getting fake mustaches in and out. Plot hole CLOSED. Also, this is a video again.",
      posted: "2010-03-11",
      is_650: 1,
      ext: "",
      link: "http://www.youtube.com/watch?v=W2MgHyew3uU",
      is_live: 1,
      rssstamp: "2010-03-10 21:00:00"
    },
    {
      id: 196,
      title: "Moon Prancing",
      alt:
        "It is difficult to walk on the moon, I think we can all relate to that.",
      posted: "2010-03-16",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2010-03-16 18:18:46"
    },
    {
      id: 197,
      title: "Scheme",
      alt:
        "I will be conducting a seminar, HOW TO DRAW A BUNK BED ONE BILLION TIMES, contact me now to secure tickets.",
      posted: "2010-03-22",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2010-03-22 21:23:46"
    },
    {
      id: 198,
      title: "Negotiations",
      alt: "All those dramatic poses, wasted!",
      posted: "2010-03-25",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2010-03-25 00:40:04"
    },
    {
      id: 199,
      title: "Gunfight",
      alt: "VIOLENCE",
      posted: "2010-04-02",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2010-04-02 06:45:54"
    },
    {
      id: 200,
      title: "Bears in Space",
      alt: "This is the 200th comic. Dang!",
      posted: "2010-04-07",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2010-04-07 13:12:16"
    },
    {
      id: 201,
      title: "Bullet Holes",
      alt:
        "IT DOESN'T SHOOT BULLETS IT'S A SPACE GUN isn't as good of a one liner.",
      posted: "2010-04-13",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2010-04-13 15:55:08"
    },
    {
      id: 202,
      title: "Space Combat",
      alt: "The space orphanage has a self destruct button, why not",
      posted: "2010-04-20",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2010-04-20 02:25:04"
    },
    {
      id: 203,
      title: "Space Dibs",
      alt: "Daisy Owl - zero orphanages blown up - YET",
      posted: "2010-04-23",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2010-04-23 11:38:55"
    },
    {
      id: 204,
      title: "Space Truck",
      alt:
        "This is a poster you can have! Click it if you would like it, my good sir or madam.",
      posted: "2010-05-04",
      is_650: 1,
      ext: "jpg",
      link: "/store/poster.php?p=P-8",
      is_live: 1,
      rssstamp: "2010-05-04 19:06:13"
    },
    {
      id: 205,
      title: "Epic Tales",
      alt: "Try to keep up with the dialogue",
      posted: "2010-05-12",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2010-05-12 10:54:00"
    },
    {
      id: 206,
      title: "Get Involved",
      alt: "Bring me my crime outfit",
      posted: "2010-06-03",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2010-06-03 04:46:21"
    },
    {
      id: 207,
      title: "Responsibilities",
      alt: "Hi.",
      posted: "2018-10-30",
      is_650: 1,
      ext: "",
      link: "",
      is_live: 1,
      rssstamp: "2018-10-30 11:45:47"
    }
  ].filter(c => c.is_live)
};
